<template>
  <div class="header" :style="{ height: showNav ? '1200px' : 'auto', background: showNav ? '#F9423A' : 'transparent' }">
    <div class="top">
      <div class="header_left">
        <div class="icon">
          <img class="arrow" @click="$router.push('/home')" src="../assets/images/back-dark.png" />
        </div> 
        <div class="logo1" @click="$router.push('/home')">
          <img src="../assets/images/head_logo_color.png" alt="">
        </div>
      </div>
      <div class="header_right">
        <div class="languages Medium">
          <!-- <el-dropdown > -->
            <span class="el-dropdown-link lan">
              <span class="Medium" @click.native="changeLanguage(lan=='Indonesian'?'Indonesian':'English')">{{lan}}</span>
              <!-- <img src="../assets/images/head_arrow_color.png" alt="" /> -->
            </span>
            <!-- <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="Medium" @click.native="changeLanguage('English')">English</el-dropdown-item>
                <el-dropdown-item class="Medium" @click.native="changeLanguage('Indonesian')">Indonesian</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown> -->
        </div>
        <div class="tip Bold"  @click.stop="showForm">Trial</div>
      </div>
    </div>
    <div class="nav" v-if="showNav">
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <img class="arrow" v-show="currentPath=='/home'" src="../assets/images/head__arrow_right.png" />
        </div>
        <span><router-link :to="'/home'">Welcome To WonderMind</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <img class="arrow" v-show="currentPath=='/age5-8' ||currentPath=='/age9-11' ||currentPath=='/age12-14'||currentPath=='/age15'  " src="../assets/images/head__arrow_right.png" />
        </div>
        <span><router-link :to="'/age5-8'">Programs By Age</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <img class="arrow"  v-show="currentPath=='/English' ||currentPath=='/math' ||currentPath=='/science'||currentPath=='/mandarin'"  src="../assets/images/head__arrow_right.png" />
        </div>
        <span><router-link :to="'/English'">Programs By Subject</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <!-- <img class="arrow" src="../assets/images/head__arrow_right.png" /> -->
      </div>
        <span><router-link :to="'/English'">Teaching Professionals</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <!-- <img class="arrow" src="../assets/images/head__arrow_right.png" /> -->
      </div>
        <span><router-link :to="'/English'">Learning Experiences</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <!-- <img class="arrow" src="../assets/images/head__arrow_right.png" /> -->
      </div>
        <span><router-link :to="'/English'">Exciting Campus Events</router-link></span>
      </div>
      <div class="nav-item Medium">
        <div class="arrow-wrap">
        <!-- <img class="arrow" src="../assets/images/head__arrow_right.png" /> -->
      </div>
        <span><router-link :to="'/English'">About Us</router-link></span>
      </div>
      <div class="smell">
        <img src="../assets/images/head_smell.png" alt="">
      </div>


    </div>
  </div>
</template>

<script setup>
import bus from '@/utils/bus';
import {
  ref,
  reactive,
  watch,
  computed
} from 'vue'
import {useRouter} from 'vue-router';
import { getLocalStorage, setLocalStorage } from '@/utils/storage';
let props = defineProps({
  index: String,
})
let language = computed(()=>{
  let l = getLocalStorage('language')
  return l=='English'?'Indonesian':'English';;
})
console.log('ww',language.value)
let emits = defineEmits(['goScroll','showForm'])
let showNav = ref(false)
let router = useRouter();
let route =router.currentRoute.value
let currentPath = ref(route.path);
let lan = ref();
lan.value = language.value;
const changeLanguage = (language) => {
  lan.value = language=='English'?'Indonesian':'English';
  setLocalStorage('language',language);
  language = language=='English'?'en':'yn'
  bus.emit('changeLan',language)
}

const showNavHandle = ()=> {
  if(currentPath.value!='/home') return;
  showNav.value = !showNav.value
}
const goScroll = (index)=> {
  showNav.value = !showNav.value
  emits('goScroll',index)
}
const showForm = ()=> {
console.log('1')
  emits('showForm')
}




</script>

<style lang="scss" scoped>
.icon {
  width: 0.2rem;
  margin-right: 0.18rem;
  display: flex;
    flex-direction: column;

  .icon-list {

    display: flex;
    flex-direction: column;

    span {
      width: 0.2rem;
      height: 0.032rem;
      border-radius: 0.04rem;
      background: rgba(34, 45, 92, 1);
      margin: 0.04rem 0;
      
    }

  }

  img {
    width: 0.16rem;
  }

}

.nav {
  width: 100%;
  height: 100%;
  padding: 0.12rem 1.56rem;
  flex: 1;
  font-size: 0.18rem;
  color: #fff;
  line-height: 0.38rem;
  box-sizing: border-box;

  .nav-item {
    display: flex;
    align-items: center;
    .arrow-wrap{
      width: 0.2rem;
      margin-right: 0.14rem;
    }

    img {
     width: 100%
      
    }
    a{
        color:#fff;
        text-decoration: none;
        &:hover{
          color:#fff;
        }
      }
  }

  .smell {

    margin: 0.52rem 0;
    text-align: center;
    width: 100%;

    img {
      width: 1.34rem;
    }

  }

}
.lan{
  font-size: 0.16rem;
    // color: #fff;
    color: rgba(34, 45, 92, 1);
    cursor: pointer;
}
.el-dropdown {
  span {
    font-size: 0.16rem;
    color: rgba(34, 45, 92, 1)
  }

  .el-dropdown-link {
    position: relative;
    display: flex;
    align-items: center;

    img {
      margin-left: 0.06rem;
      height: 0.06rem;
      margin-top: 0.02rem;

    }
  }

  .el-icon {
    width: auto;
    height: auto;
  }
}

.header {
  width: 100%;
  // background: #F9423A;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,.05);

  .top {
    width: 100%;
    display: flex;
    padding: 0.12rem 0.6rem;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
  }

  .header_left {
    display: flex;
    align-items: center;

    .logo1 {
      display: flex;

      img {
        width: 0.78rem;
        height: 0.225rem;
      }
    }
  }

  .header_right {
    display: flex;
    align-items: center;

    .languages{
      font-size: 0.12rem;
      color:rgba(34, 45, 92, 1)
    }
    .tip{
      width: 0.72rem;
      height: 0.28rem;
      font-size: 0.14rem;
      margin-left: 0.3rem;
      background: #F9423A;
      color:#fff;
      border-radius: 0.06rem;
      text-align: center;
      line-height: 0.28rem;
      cursor: pointer;
    }
  }
}</style>
