<template>
  <div class="fullpage">
    <div class="page-title ExtraBold" v-if="language == 'en'">
      <div style="color:#222D5C">VIEW PROGRAMS</div> 
      <div><span style="color:#222D5C">BY</span> SUBJECT</div>
      
    </div>
    <div class="page-title ExtraBold" v-if="language == 'yn'">
      <div style="color:#222D5C">PROGRAM BERDASARKAN</div> 
      <div>MATA PELAJARAN</div>
    </div>

    <div class="list">
      <div class="item" id="item1" @click="$router.push('/english')">
        <img class="bg" style="width:124%" src="../../assets/images/english/full1_img1.png" alt="">
        <div class="shadow"></div>
        <div class="card-title Bold">English</div>
        <div class="info " id="info1">
          <div class="title Bold">English</div>
          <div class="desc Bold" v-if="language == 'en'">The iWonder English course is designed specifically for Indonesian students. It is aligned with the curriculum of the Indonesian Ministry of Education and covers the content of most school textbooks.</div>
          <div class="desc Bold" v-if="language == 'yn'">Program iWonder English didesain khusus untuk pelajar Indonesia sesuai dengan kurikulum Menteri Pendidikan Indonesia dan pelajaran di sekolah-sekolah.</div>
          <div class="arrow Bold"><img src="../../assets/images/home/full3_arrow.png" /></div>
        </div>
      </div>
      <div class="item" id="item2" @click="$router.push('/mandarin')">
        <img  class="bg" src="../../assets/images/home/full3_img2.png" alt="">
        <div class="shadow"></div>
        <div class="card-title Bold">Mandarin</div>
        <div class="info ">
          <div class="title Bold">Mandarin</div>
          <div class="desc Bold" v-show="language == 'en'">The iWonder Mandarin Course is developed by experienced Chinese teaching experts, using the YCT and HSK syllabi as its foundation. It offers a comprehensive curriculum for learners of all ages, starting from the beginner level and advancing to professional-level communication proficiency.</div>
          <div class="desc Bold" v-show="language == 'yn'">iWonder Mandarin dikembangkan oleh para ahli pengajar yang berpengalaman, berdasarkan silabus YCT dan HSK. Program ini menawarkan kurikulum yang komprehensif untuk siswa dari segala usia, mulai dari pemula hingga tingkat profesional.</div>
          <div class="arrow Bold"><img src="../../assets/images/home/full3_arrow.png" /></div>
        </div>
      </div>
    </div>
    <div class="list">
      <div class="item" @click="$router.push('/math')">
        <img  class="bg" src="../../assets/images/home/full3_img3.png" alt="" style="height: 125%;">
        <div class="shadow"></div>
        <div class="card-title Bold">Math</div>
        <div class="info ">
          <div class="title Bold">Math</div>
          <div class="desc Bold" v-show="language == 'en'">We're in the process of getting everything ready; please check back soon.</div>
          <div class="desc Bold" v-show="language == 'yn'">Kami sedang dalam proses mempersiapkan program ini. Nantikan kehadiran kami.</div>
          <div class="arrow Bold"><img src="../../assets/images/home/full3_arrow.png" /></div>
        </div>
      </div>
      <div class="item" @click="$router.push('/science')">
        <img  class="bg" src="../../assets/images/home/full3_img4.png" alt="">
        <div class="shadow"></div>
        <div class="card-title Bold">Science</div>
        <div class="info ">
          <div class="title Bold">Science</div>
          <div class="desc Bold" v-show="language == 'en'">We're in the process of getting everything ready; please check back soon.</div>
          <div class="desc Bold" v-show="language == 'yn'">Kami sedang dalam proses mempersiapkan program ini. Nantikan kehadiran kami.</div>
          <div class="arrow Bold"><img src="../../assets/images/home/full3_arrow.png" /></div>
        </div>
      </div>
    </div>
 
  </div>

</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let bigTitle = reactive({
  eng:'WELCOME TO WONDERMIND',
  yinni:'welcome to WonderMind~',
})


</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';
.fullpage{
  width: 100%;
  height: 100%;
  background:#fff;
  display: flex;
  flex-direction: column;
  
  .page-title{
    margin:0.3rem 1.15rem 0.3rem 1.15rem;
    font-size: .27rem;
    line-height: 0.28rem;
    color:#05C3DE;
  }

}
.list{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.36rem;
  .item{
    width: 3.06rem;
    height: 1.67rem;
    position: relative;
    background-position: center center;
    background-size: 100% 100%;
    background: #fff;
    border-radius: 0.07rem;
    overflow: hidden;
    margin: 0 0.28rem;
    &:hover{
      .info{
        left: 0;
      }
    }
    .bg{
      z-index: 77;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .shadow{
      z-index: 88;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(8, 8, 8, 0.2) 76.82%, rgba(43, 43, 43, 1) 100%);
    }
    .card-title{
      z-index: 90;
      position: absolute;
      left: 0.18rem;
      bottom:0.18rem;
      font-size: 0.18rem;
      color:#fff;
    }
    .info{
      z-index: 99;
      position: absolute;
      left:-3.67rem;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(249, 66, 58, 1);
      color:#fff;
      padding: 0.14rem 0.18rem;
      box-sizing: border-box;
      transition: all .4s ease-in-out;
      .title {
        font-size: 0.2rem;
        margin-bottom: 0.08rem;
      }
      .desc{
        font-size: .1rem;
        margin-bottom: 0.06rem;
        line-height: 0.12rem;
      }
      .arrow{
        display: flex;
        justify-content: flex-end;
        margin-top: 0.12rem;
        position: absolute;
        right: 0.26rem;
        bottom: 0.14rem;
        img{
          width:0.2rem;
          height: 0.16rem;
        }
      }
    }
  }
  // .item1{
  //   background-image: url('');
  // }
  
}


</style>
