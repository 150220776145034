<template>
  <div class="fullpage">
    <div class="page-title ExtraBold" v-if="language == 'en'">
      <div style="color:#222D5C">VIEW PROGRAMS</div>  
      <div><span style="color:#222D5C">BY</span>  AGE</div>
    </div>
    <div class="page-title ExtraBold" v-if="language == 'yn'">
      <div style="color:#222D5C">PROGRAM BERDASARKAN</div> 
      <div>USIA</div>
    </div>


    <div class="list">
      <div class="item" @click="$router.push('/age5-8')">
        <img src="../../assets/images/home/full4_img1.png" alt="">
        <div class="card-title  Bold">
            <div class="title1">EXPLORERS</div>
            <div class="title2">5-8 <span>{{ language == 'en'? 'YEARS':'TAHUN'}}</span> </div>
        </div>
         </div>
        <div class="item" @click="$router.push('/age9-11')">
          <img src="../../assets/images/home/full4_img2.png" alt="">
        <div class="card-title  Bold">
            <div class="title1">SEEKERS</div>
            <div class="title2">9-11 <span>{{ language == 'en'? 'YEARS':'TAHUN'}}</span> </div>
        </div>
      </div>
      <div class="item" @click="$router.push('/age12-14')">
        <img src="../../assets/images/home/full4_img3.png" alt="">
        <div class="card-title  Bold">
            <div class="title1">PIONEERS</div>
            <div class="title2">12-14 <span>{{ language == 'en'? 'YEARS':'TAHUN'}}</span> </div>
        </div>
      </div>
      <div class="item" @click="$router.push('/age15')">
        <img src="../../assets/images/home/full4_img4.png" alt="">
        <div class="card-title  Bold">
            <div class="title1">ACHIEVERS</div>
            <div class="title2">15-18 <span>{{ language == 'en'? 'YEARS':'TAHUN'}}</span> </div>
        </div>
      </div>
    </div>
    
 
  </div>

</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let bigTitle = reactive({
  eng:'WELCOME TO WONDERMIND',
  yinni:'welcome to WonderMind~',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';
.fullpage{
  width: 100%;
  height: 100%;
  background:#fff;
  display: flex;
  flex-direction: column;
  
  .page-title{
    margin:0.3rem 1.15rem 0.3rem 1.15rem;
    font-size: .27rem;
    line-height: 0.28rem;
    color:#05C3DE;
  }

}
.list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.86rem;
  padding: 0 0.85rem;
  .item{
    width: 1.49rem;
    height:2.67rem;
    position: relative;
    top:0;
    background-position: center center;
    background-size: 100% 100%;
    background: #fff;
    border-radius: 0.07rem;
    overflow: hidden;
    margin: 0 0.15rem;
    // background-color: #999;
    transition: all .6s;
    img{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;;
    }
    &:hover{
      top: -10px;
    }
    .card-title{
      position: relative;
      transform: skewX(-15deg);
      z-index:9;
        text-align: center;
        color:#fff;
        margin-top: 0.32rem;
        .title1{
            font-size:0.2rem;
        }
        .title2{
            font-size:0.16rem;
        }
    }
   
    
  }
  // .item1{
  //   background-image: url('');
  // }
  
}


</style>
