import axios from 'axios';


axios.defaults.baseURL = 'http://www.wondermindedu.com';
// axios.defaults.baseURL = 'http://www.wondermindedu.com';
                            //  www.wondermindedu.com/login
// axios.defaults.withCredentials = true;



axios.interceptors.request.use(function (res) {

    res.headers['Token'] = 'iH6uI0oQ3rX6sL0x';
    return res;

});



export function requsetGet(url, params, showToast = true, cb) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params
        })
            .then(function (response) {
                let res = response.data
                resolve(res)
            })
            .catch(function (error) {

                reject(error)


            });

    })
}

export function requsetPost(url, params, headers, showToast = true, cb) {
    return new Promise((resolve, reject) => {
        axios.post(url, params, headers ? { headers } : {})
            .then(function (response) {
                let res = response.data


                resolve(res)

            })
            .catch(function (error) {
                console.log(error)
                reject(error)
            });

    })
}
export function requsetPut(url, params, showToast = true, cb) {
    return new Promise((resolve, reject) => {
        axios.put(url, params)
            .then(function (response) {
                let res = response.data

                resolve(res)

            })
            .catch(function (error) {

                reject(error)

            });

    })
}

export function requsetDelete(url, params, showToast = true, cb) {
    return new Promise((resolve, reject) => {
        axios.delete(url, {
            params
        })
            .then(function (response) {
                let res = response.data

                resolve(res)

            })
            .catch(function (error) {

                reject(error)

            });

    })
}




