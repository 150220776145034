<template>
  <div class="fullpage">
    <div class="t animate__animated animate__fadeIn animate_slow_3" >
      <h1 class="t1 Bold" v-html="language == 'en'?topTitle.eng:topTitle.yinni"></h1>
      <h1 class="t2 SemiBold" v-html="language == 'en'?bigTitle.eng:bigTitle.yinni"></h1>
    </div>
    <div class="icons">
        <img class="img_bg" v-if="language == 'en'" src="../../assets/images/home/full2_img3.png" alt="">
        <img class="img_bg"  v-if="language == 'yn'" src="../../assets/images/home/full2_img3_yinni.png" alt="">
        <img class="img_center animate__animated animate__zoomIn" v-if="language == 'en'" src="../../assets/images/home/full2_img2.png" alt="">
        <img class="img_center animate__animated animate__zoomIn" v-if="language == 'yn'" style="top:0.58rem" src="../../assets/images/home/full2_img2_yinni.png" alt="">
        <div class="Medium block empty1 animate__animated animate__flash animate__infinite animate_slow_5" :style="{width:language == 'en'?'0.68rem':'1rem'}"><span >{{ language == 'en'?'RESILIENCE':'KETANGGUHAN' }}</span></div>
        <div class="Medium block empty2 animate__animated animate__flash animate__infinite animate_slow_10" :style="{width:language == 'en'?'0.68rem':'0.8rem'}"><span>{{ language == 'en'?'CREATIVITY':'KREATIVITAS' }}</span></div>
        <div class="Medium block empty3 animate__animated animate__flash animate__infinite animate_slow_8" :style="{width:language == 'en'?'0.8rem':'0.68rem'}"><span>{{ language == 'en'?'INNOVATION':'INOVASI' }}</span></div>
        <div class="Medium block empty4 animate__animated animate__flash animate__infinite animate_slow_10" :style="{width:language == 'en'?'0.68rem':'0.68rem'}"><span>{{ language == 'en'?'PASSION':'SEMANGAT' }}</span></div>
        <div class="Medium block empty5 animate__animated animate__flash animate__infinite animate_slow_8" :style="{width:language == 'en'?'0.68rem':'0.68rem'}"><span>{{ language == 'en'?'POTENTIAL':'POTENSI' }}</span></div>
        <div class="Medium block empty6 animate__animated animate__flash animate__infinite animate_slow_5" :style="{width:language == 'en'?'0.68rem':'0.6rem'}"><span>{{ language == 'en'?'THOUGHT':'IDE' }}</span></div>
        <div class="Medium full full1 animate__animated animate__flash animate__infinite animate_slow_5" :style="{width:language == 'en'?'0.68rem':'0.68rem'}"><span>{{ language == 'en'?'VISION':'VISI' }}</span></div>
        <div class="Medium full full2 animate__animated animate__flash animate__infinite animate_slow_10" :style="{width:language == 'en'?'0.68rem':'0.68rem'}"><span>{{ language == 'en'?'VITALITY':'ANTUSIAS' }}</span></div>
        <div class="Medium full full3 animate__animated animate__flash animate__infinite animate_slow_8" :style="{width:language == 'en'?'0.78rem':'1rem'}"><span>{{ language == 'en'?'INSPIRATION':'INSPIRASI' }}</span></div>
        <div class="Medium full full4 animate__animated animate__flash animate__infinite animate_slow_10" :style="{width:language == 'en'?'0.68rem':'1rem',right:language == 'en'?'-0.14rem':'-0.44rem'}"><span>{{ language == 'en'?'GROWTH':'PERTUMBUHAN' }}</span></div>
        <div class="Medium full full5 animate__animated animate__flash animate__infinite animate_slow_8" :style="{width:language == 'en'?'0.8rem':'1rem'}"><span>{{ language == 'en'?'CONFIDENT':'PERCAYA DIRI' }}</span></div>
        <div class="Medium full full6 animate__animated animate__flash animate__infinite animate_slow_5" :style="{width:language == 'en'?'0.8rem':'0.8rem'}"><span>{{ language == 'en'?'PROGRESS':'KEMAJUAN' }}</span></div>
    </div>
  </div>

</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let topTitle = reactive({
  eng:'OUR MISSION',
  yinni:'MISI KAMI',
})
let bigTitle = reactive({
  eng:'To bring the best experiences and <br/> achivements to the learners <br/> through love, content, and technology.',
  yinni:'Untuk menghadirkan pengalaman dan <br/> prestasi terbaik kepada para pelajar <br/> melalui cinta, konten, dan teknologi.',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';
.fullpage{
  width: 100%;
  min-width:900px;
//   height: 5.4rem;
  height: 100%;
  background:#222D5C;
  display: flex;
  flex-direction: column;
  color:#fff;
  align-items: center;
    justify-content: center
}
.t{
    margin-top:0.48rem;
    margin-bottom:0.26rem;
    text-align: center;
    .t1{
        font-size: 0.18rem;
        color:#05C3DE;
        // margin-bottom: 0.05rem;
    }
    .t2{
        font-size: 0.32rem;
        line-height: 0.34rem;

    }
}
.icons{
    width: 100%;
    // flex:1;
    position: relative;
    width: 2.9rem;
    height: 2.56rem;
    margin-bottom: 0.43rem;
    img{
        position: absolute;
        left:50%;
        top: 50%;
        width: 2.9rem;
    height: 2.56rem;
        margin-left: -1.46rem;
        // margin-top: -1.14rem;
    }
    .img_bg{
        position: absolute;
        left:50%;
        top: 50%;
        top: 0;
       
    }
    .img_center{
        width: 1.36rem;
        height: 1.36rem;
        top: 0.72rem;;
        margin-left: -0.72rem;
    }
    .block{
        position: absolute;
        min-width: 0.62rem;
        padding: 0 .06rem;
        height: 0.26rem;
        line-height: 0.24rem;
        border-radius: 0.13rem;
        text-align: center;
        border:1px solid rgb(83,176,245);
        box-sizing: border-box;
        opacity: 0.3;
        span{
            color:rgb(255,255,255);
            font-size: .12rem;
            transform: scale(0.75);
            transform-origin: center center;
            display: block;
                
        }
    }
    .empty1{
        // width: 0.68rem;
        padding: 0;
        text-align: center;
       left: -1.4rem;
        // top: 18%;
        top: 0.21rem;
    }
    .empty2{
        // width: 0.68rem;
        padding: 0;
        // left: 16%;
        left: -1.83rem;
        // top: 52%;
        top: 1.2rem;
    }
    .empty3{
        width: 0.8rem;
        padding: 0;
        left: 23%;
        left: -0.99rem;
        // top: 84%;
        top: 2.54rem;
    }
    .empty4{
        width: 0.68rem;
        padding: 0;
        right: -1.15rem;
        top: 0.21rem;
    }
    .empty5{
        width: 0.68rem;
        padding: 0;
        // right: 22%;
        right: -1.84rem;
        // top: 52%;
        top: 1.2rem;
    }
    .empty6{
        width: 0.62rem;
        padding: 0;
        // right: 22%;
        right: -1.24rem;
        // top: 84%;
        top: 2.53rem;
    }
    .full{
        position: absolute;
        min-width: 0.62rem;
        padding: 0 .06rem;
        height: 0.26rem;
        line-height: 0.24rem;
        border-radius: 0.13rem;
        text-align: center;
        border:1px solid rgb(83,176,245);
        box-sizing: border-box;
        
        span{
            color:rgb(255,255,255);
            font-size: .12rem;
            transform: scale(0.75);
            transform-origin: center center;
            display: block;
            opacity: 0.5;
                
        }
    }
    .full1{
        width: 0.62rem;
        padding: 0;
        // left: 33%;
        left: -0.14rem;
        top: 25%;
        top: 0.43rem;
    }
    .full2{
        width: 0.62rem;
        padding: 0;
        // left: 24%;
        left: -0.93rem;
        // top: 42%;
        top: 0.84rem;
    }
    .full3{
        width: 0.88rem;
        padding: 0;
        // left: 23%;
        left: -1.03rem;
        // top: 68%;
        top: 1.81rem;
    }
    .full4{
        width:0.65rem;
        padding: 0;
        // right: 31%;
        right: -0.14rem;
        // top: 25%;
        top: 0.43rem;
    }
    .full5{
        width: 0.8rem;
        padding: 0;
        right: -0.95rem;
        // right: 22%;
        // top: 42%;
        top: 0.9rem;
    }
    .full6{
        width: 0.8rem;
        right: -0.95rem;
        // right: 23%;
        // top: 68%;
        top: 1.81rem;
    }
}


.animate_slow_3{
    -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  -o-animation-duration:3s;
  animation-duration: 3s;
  --animate-duration: 3s;
}
.animate_slow_5{
    -webkit-animation-duration: 5s;
  -moz-animation-duration: 5s;
  -o-animation-duration:5s;
  animation-duration: 5s;
  --animate-duration: 5s;
}
.animate_slow_8{
    -webkit-animation-duration: 8s;
  -moz-animation-duration: 8s;
  -o-animation-duration:8s;
  animation-duration: 8s;
  --animate-duration: 8s;
}
.animate_slow_10{
    -webkit-animation-duration: 10s;
  -moz-animation-duration: 10s;
  -o-animation-duration:10s;
  animation-duration: 10s;
  --animate-duration: 10s;
}

</style>
