<template>
  <div class="fullpage">
    <div class="left" :style="{'padding-left':language == 'en'?'1.15rem':'0.6rem'}">
      <div class="title animate__animated animate__fadeInDown" v-if="language == 'en'">
        <div class="h3 Bold">WONDERMIND'S TEACHING</div>
        <div class="h3 Bold">PROFESSIONALS</div>
        <p class="line"></p>
      </div>
    
      <div class="title animate__animated animate__fadeInDown" v-if="language == 'yn'">
        <div class="h3 Bold">PENGAJAR PROFESIONAL </div>
        <div class="h3 Bold">WONDERMIND</div>
        <p class="line"></p>
      </div>
    
      <div class='bigTitle Bold animate__animated animate__fadeInUp' v-if="language == 'en'">
        <p>WE EXCLUSIVELY</p>
        <p>EMPLOY FULL-TIME  </p>
        <p>TEACHERS.</p>
      </div>
      <div class='bigTitle Bold animate__animated animate__fadeInUp' v-if="language == 'yn'">
        <p>PENGAJAR FULL-TIME</p>
        <p>YANG PROFESIONAL </p>
        <p>DAN BERPENGALAMAN</p>
      </div>
     
    </div>
    <div class="right">
      <div class="right-top">
        <img class="bg" src="../../assets/images/home/full5_img1.png" alt="">
        <!-- <img class="logo" src="../../assets/images/home/full6_img1_logo.png" alt=""> -->
      </div>
      <div class="right-bottom">
        <div class="b1">
          <div class="b1-1">
          <div class="wrap">
            <div class="short-line"></div>
            <p class="txt Medium" v-if="language == 'en'">
              <span>Graduated from</span>
              <span><i style="font-weight: bold;"> prestigious universities</i></span>
              <span> in Singapore, China,</span>
              <span> Indonesia, and around</span>
              <span> the world.</span>
            </p>
            <p class="txt Medium" v-if="language == 'yn'">
              <span>Lulusan universitas </span>
              <span>unggulan dari Singapura, </span>
              <span>China, Indonesia, dan </span>
              <span>sekeliling dunia.</span>
            </p>
          </div>
          </div>
          <div class="b1-2">
            <div class="wrap">
            <div class="short-line"></div>
            <p class="txt Medium" v-if="language == 'en'">
              <span>Evaluated based on</span>
              <span><i style="font-weight: bold;">students’ progress</i> and</span>
              <span>parental satisfaction. </span>
            </p>
            <p class="txt Medium" v-if="language == 'yn'">
              <span>Pengajar dievaluasi </span>
              <span>menurut kemajuan siswa </span>
              <span>dan kepuasan orang tua. </span>
            </p>
          </div>
          </div>
        </div>
        <div class="b2">
          <div class="wrap">
          <div class="short-line"></div>
            <p class="txt Medium" style="width: 1.8rem" v-if="language == 'en'">
              <span>Have undergone rigorous</span>
              <span><i style="font-weight: bold;">training</i> programs that cover</span>
              <span>various aspects, including </span>
              <span>teaching psychology,</span>
              <span>classroom instructional  </span>
              <span>methods, and curriculum</span>
              <span>analysis. </span>
            </p>
            <p class="txt Medium" style="width: 1.8rem" v-if="language == 'yn'">
              <span>Telah menjalani program </span>
              <span>pelatihan komprehensif yang </span>
              <span>mencakup berbagai aspek,</span>
              <span>termasuk psikologi, metode </span>
              <span>pengajaran kelas, dan </span>
              <span>analisis kurikulum.</span>

            </p>
            </div>
            
            <img class="animate__animated animate__fadeInUp animate_yc_1" src="../../assets/images/home/full5-img2.png" alt="">
        </div>
      </div>
    </div>
  </div>

</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let title1 = reactive({
  eng:" ",
  yinni:'welcome to WonderMind~',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';
.fullpage{
  width: 100%;
  height: 100%;
  background:#222D5C;
  display: flex;
  .left{
    width: 50%;
    padding-left: 1.15rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
 
    .title{
       margin-bottom:0.12rem;
       margin-top:1rem;
       position: absolute;
       top: 0;
       left:1.15rem;
       .h3{
      font-size: 0.18rem;
      // line-height: 0.34rem;
      color:#05C3DE;
     
    }
    .line{
      width: 0.6rem;
      height: 0.03rem;
      background: #05C3DE;
      margin-bottom: 0.32rem;
      margin-top: 0.15rem;
    }
    }
   
    .bigTitle{
      font-size: 0.32rem;
      line-height: 0.34rem;
      color:#fff;
    }
  }
  .right{
    width: 50%;
    background: #000;
    
    .right-top{
      width: 100%;
      height: 47%;
      background: #ddd;
      overflow: hidden;
      position: relative;
      .bg{
        position: relative;
        top: -0.1rem;
        width: 100%;
        // height: 127%;
      }
      .logo{
        position: absolute;
        top: 0.64rem;
    left: 1.86rem;
        width: 0.37rem;
      }
    }
    .right-bottom{
      width: 100%;
      height: 53%;
      background: #999;
      display: flex;
      .b1 {
        width: 42%;
        height: 100%;
        background: #aaa;
        .b1-1{
          padding: 0.2rem 0.2rem 0.2rem 0.18rem;
          box-sizing: border-box;
          height: 50%;
          background: rgba(249, 66, 58, 1);
          overflow: hidden;
          .wrap{
            transition: all 0.6s;
          }
          &:hover{
            .wrap{

              transform: scale(1.15);
            }
          }
        }
        .b1-2{
          padding: 0.2rem 0.18rem;
          box-sizing: border-box;
          height: 50%;
          background: rgba(255, 163, 0, 1);
          overflow: hidden;
          .wrap{
            transition: all 0.6s;
          }
          &:hover{
            .wrap{

              transform: scale(1.1);
            }
          }
        }
      }
      .b2{
        padding: 0.2rem 0.18rem;
        box-sizing: border-box;
        width: 58%;
        height: 100%;
        background: #05C3DE;
        overflow: hidden;
          .wrap{
            transition: all 0.6s;
          }
          &:hover{
            .wrap{

              transform: scale(1.1);
            }
          }
        img{
          width: 1.22rem;
          display: block;
          margin: 0.36rem auto 0;
        }
      }
    }
  }

}
.short-line{
  width: 0.1rem;
  height: 0.03rem;
  background: #fff;
  margin-bottom: 0.09rem;
}
.txt{
  font-size: 0.1rem;
  line-height: 0.14rem;
  display: flex;
  flex-direction: column;
  color:#fff;
}
.animate_yc_1{
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay:1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}

</style>
