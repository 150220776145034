import { createRouter, createWebHistory ,createWebHashHistory} from 'vue-router';
import Login from '../pages/Login.vue';
import Age911 from '../pages/age9_11copy/Index.vue';
import Home from '../pages/index/Index.vue';
import Age5_8 from '../pages/age5_8/Index.vue';
import Age12_14 from '../pages/age12_14/Index.vue';
import Age15 from '../pages/age15/Index.vue';
import English from '../pages/SubjectEnglish/Index.vue';
import Mandarin from '../pages/SubjectMandarin/Index.vue';
import Math from '../pages/SubjectMath/Index.vue';
import Science from '../pages/SubjectScience/Index.vue';
// import Empty from '../pages/SubjectEmpty/Index.vue'
// import Age911 from '../pages/ageNine/Index.vue';

const routes = [
  
  {
    path: '/',
    component: Home,
    redirect:'/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/age5-8',
    name: 'Age5_8',
    component: Age5_8
  },
  // {
  //   path: '/age9-11',
  //   // name: 'Age9_11',
  //   component: Age911,
  // },
  {
    path: '/age9-11',
    // name: 'Age9_11',
    component: Age911,
  },
  {
    path: '/age12-14',
    name: 'Age12_14',
    component: Age12_14
  },
  {
    path: '/age15',
    name: 'Age15',
    component: Age15
  },
  {
    path: '/english',
    name: 'English',
    component: English
  },
  {
    path: '/mandarin',
    name: 'Mandarin',
    component: Mandarin
  },
  {
    path: '/math',
    name: 'Math',
    component: Math
  },
  {
    path: '/science',
    name: 'Science',
    component: Science
  },
  // {
  //   path: '/non-subject',
  //   name: 'Empty',
  //   component: Empty
  // }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
