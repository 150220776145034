<template>
  <div class="fullpage">
    <div class="top">
      <div class="title ExtraBold" v-if="language == 'en'">
        <div style="color:#222D5C">JOYFUL AND PRODUCTIVE</div>
        <div> <span style="color:#222D5C">LEARNING</span> EXPERIENCES</div>
      </div>
      <div class="title ExtraBold" v-if="language == 'yn'">
        <div style="color:#222D5C">PENGALAMAN PEMBELAJARAN YANG</div>
        <div>PRODUKTIF & MENYENANGKAN</div>
      </div>
      <div class="desc Medium" v-if="language == 'en'">
        <span>In WonderMind, students lead,</span>
        <span>teachers guide. We foster a joyful</span>
        <span>environment, encourage active</span>
        <span>participation, and empower</span>
        <span>students in their own learning</span>
        <span>journey.</span>
      </div>
      <div class="desc Medium" v-if="language == 'yn'">
        <span>Di WonderMind, siswa</span>
        <span> memimpin, guru membimbing.</span>
        <span>Kami membangun lingkungan</span>
        <span>pembelajaran yang </span>
        <span>menyenangkan dan mendorong </span>
        <span>siswa aktif dalam pembelajaran.</span>
      </div>
    </div>
    <div class="bottom">
      <div class="blocks">
        <div class="item item1 Bold">
          <img src="../../assets/images/home/full6_img1_logo.png" alt="">
          <span> {{language == 'en'?'Interactive':'Interaktif'}}</span> 
        </div>
        <div class="item item2 animate">
          <img src="../../assets/images/home/full6_img1.png" alt="">
        </div>
        <div class="item item3 Bold">{{language == 'en'?'Engaging':'Menarik'}}</div>
        <div class="item item4 animate">
          <img src="../../assets/images/home/full6_img2.png" alt="">
        </div>
        <div class="item item5 Bold">
          <span>{{language == 'en'?'Confidence-':'Membangun'}}</span>
          <span>{{language == 'en'?'boosting':'Kepercayaan Diri'}}</span>
        </div>
      </div>
      <div class="blocks">
        <div class="item item6 animate">
          <img src="../../assets/images/home/full6_img3.png" alt="">

        </div>
        <div class="item item7 Bold">{{language == 'en'?'Productive':'Produktif'}}</div>
        <div class="item item8 animate">
          <img src="../../assets/images/home/full6_img4.png" alt="">

        </div>
        <div class="item item9 Bold">
          <span>{{language == 'en'?'Well-':'Terstruktur'}}</span>
          <span>{{language == 'en'?'structured':''}}</span>
        </div>
        <div class="item item10 animate">
          <img src="../../assets/images/home/full6_img5.png" alt="">

        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let title1 = reactive({
  eng: " ",
  yinni: 'welcome to WonderMind~',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.fullpage {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0.36rem 1.15rem;
  box-sizing: border-box;

  .top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.28rem;

    .title {
      font-size: 0.27rem;
      line-height: 0.3rem;
      color: #05C3DE;
    }

    .desc {
      display: flex;
      flex-direction: column;
      font-size: 0.1rem;
      line-height: 0.12rem;
      color: #282828;
    }
  }
}
.bottom{
  .blocks{
    display: flex;
    background: #000;
    .item{
      height:1.63rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0.16rem;
      color:#fff;
      img{
        height: 101%;
        width:102%;
      }
    }
    .animate{
      overflow: hidden;
      img{
        transition: all 0.6s;
      }
      &:hover{
        img{
          transform: scale(1.1);
        }
        
      }
    }
    .item1{
      width:1.46rem;
      background: rgba(249, 66, 58, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      img{
        width: 0.32rem;
        height: 0.32rem;;
      }
      span{
        margin-top: -0.06rem;
      }
    }
    .item2{
      width:1.46rem;
      background: #fff;
    }
    .item3{
      width:1.24rem;
      background: rgba(255, 163, 0, 1);
    }
    .item4{
      width:1.3rem;
      background: #fff;
      img{
        width: auto;
        height: 200%;
    position: relative;
    left: 0.7rem;
    top: -0.2rem;
      }
    }
    .item5{
      width:1.84rem;
      background: rgba(34, 45, 92, 1);
    }
    .item6{
      width:1.26rem;
      background: #fff;
    }
    .item7{
      width:1.26rem;
      background: rgba(5, 195, 222, 1);
    }
    .item8{
      width:1.34rem;
      background: #fff;
    }
    .item9{
      width:1.6rem;
      background: rgba(0, 198, 94, 1);
    }
    .item10{
      width:1.84rem;
      background: #fff;
    }
  }
}
</style>
