<template>
    <div class="fullpage" @click.stop="hideFormWrap">
        <div class="left" v-if="language=='en'">
            <div class="t1 Bold">PROGRAMS FOR</div>
            <div class="t2 ExtraBold">9-11 YEARS</div>
            <div class="t3 Bold">A leap in learning ability.</div>
            <div class="t4 Medium">Language skills blossom, nurturing cooperation, sharing, and attentive listening. Abstract thinking flourishes, along with enhanced reasoning. </div>
            <div class="t5 Medium">At WonderMind, we call it the “Seekers” stage, where we offer plentiful learning resources to boost skills and critical thinking.</div>
            <div class="btn Bold animate__animated animate__heartBeat" @click.stop="showFormWrap"> Try A Class</div>
        </div>
        <div class="left" v-if="language=='yn'">
            <div class="t1 Bold">PROGRAM UNTUK</div>
            <div class="t2 ExtraBold">9-11 TAHUN</div>
            <div class="t3 Bold">Kemajuan dalam kemampuan <br />belajar.</div>
            <div class="t4 Medium">Setelah keterampilan berbahasa berkembang, siswa memupuk kerja sama, rasa berbagi, dan tingkat konsentrasi. Pemikiran yang abstrak berkembang seiring dengan peningkatan penalaran. </div>
            <div class="t5 Medium">Di WonderMind, kami menyebutnya tahap “Seekers”, di mana kami menawarkan banyak sumber belajar untuk meningkatkan keterampilan dan pemikiran kritis.</div>
            <div class="btn Bold animate__animated animate__heartBeat" @click.stop="showFormWrap">Coba Kelas</div>
        </div>
        <div class="right">
            <div class="wrap">
                <img src="../../assets/images/age9_11/full2_img1.png" />
            </div>
            <div class="wrap">
                <img src="../../assets/images/age9_11/full2_img2.png" />
            </div>
        </div>
        <div class="form-wrap2" id="form"  @click.stop=" bus.emit('showForm')">
            <Form :sendBg="'#fff'" :sendColor="'rgba(249, 66, 58, 1)'" :language="language" />
        </div>
    </div>
</template>
<script setup>
import bus from '@/utils/bus';
import Form from '../../components/Form.vue'
import {onMounted, ref} from 'vue';
let props = defineProps({
  language:String
})

let form =null
onMounted(()=>{
    form = document.getElementById('form')
})
const showFormWrap = () =>{
    form.style.bottom = '0px';
}
const hideFormWrap = () =>{
    form.style.bottom = '-600px';
}

bus.on('hideForm',()=>{
  console.log('4')
  form.style.bottom = '-600px';
})
bus.on('showForm',()=>{
  console.log('3')
  form.style.bottom = '0px';
})

</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.form-wrap2{
    position: fixed;
    left: 0;
    bottom:-600px;
    width: 100%;
    height: 1.8rem;
    background: rgba(249, 66, 58, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.15rem;
    box-sizing: border-box;
    transition: all .5s;
    z-index:2000;
}

.fullpage {
    width: 100%;
    height: 100%;
    background: rgba(34, 45, 92, 1);
    display: flex;
    position: relative;
}

.left {
    width: 52%;
    padding-left: 1.15rem;
    // padding-right: 0.2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .t1 {
        font-size: 0.18rem;
        color: #05C3DE;
        line-height: 0.24rem;
        margin-top: -0.2rem;
    }

    .t2 {
        font-size: 0.45rem;
        color: #FFF;
        line-height: 0.54rem;
        margin-bottom: 0.37rem;
    }

    .t3 {
        font-size: 0.19rem;
        line-height: 0.22rem;
        margin-bottom: 0.52rem;
        color: #FFF;
    }

    .t4 {
        font-size: 0.126rem;
        line-height: 0.18rem;
        margin-bottom: 0.12rem;
        color: #FFF;
        margin-right: 0.5rem;
    }

    .t5 {
        font-size: 0.112rem;
        color: #FFF;
        margin-bottom: 0.32rem;
        margin-right: 0.4rem;
    }

    .btn {
        width: 1.26rem;
        height: 0.34rem;
        background: rgba(249, 66, 58, 1);
        border-radius: 0.05rem;
        font-size:0.14rem;
        line-height:0.34rem;
        text-align:center;
        color:#fff;
    }
}

.right {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding-right: 1.15rem;
    box-sizing: border-box;

    .wrap {
        margin: 0.16rem 0;
        width: 3.29rem;
        height: 1.85rem;

        img {
            width: 100%;
            height: 100%;
        }
    }
}</style>