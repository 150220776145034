<template>
  <div class="footer">
    <div class="footer-logo" >
      <img src="../assets/images/footer-logo.png" alt="" @click="$router.push('/home')">
    </div>
    <div class="center">
      <div class="lists">
        <div class="list list1">
          <p class="title SemiBold" v-if="language=='en'">Programs by age</p>
          <div class="title SemiBold" style="font-size:0.14rem" v-if="language=='yn'">
            <p>program</p> 
            <p>berdasarkan usia</p> 
          </div>
          <span class="Medium"><router-link :to="'/age5-8'" >5-8 {{language=='en'?'years':'tahun'}}</router-link></span>
          <span class="Medium"><router-link :to="'/age9-11'" >9-11 {{language=='en'?'years':'tahun'}}</router-link></span>
          <span class="Medium"><router-link :to="'/age12-14'" >12-14 {{language=='en'?'years':'tahun'}}</router-link></span>
          <span class="Medium"><router-link :to="'/age15'" >15-18 {{language=='en'?'years':'tahun'}}</router-link></span>
        </div>
        <div class="list list1">
          <p class="title SemiBold" v-if="language=='en'">Programs by subject</p>
          <p class="title SemiBold" style="font-size:0.14rem" v-if="language=='yn'">
            <p>Program berdasarkan</p>
            <p>mata pelajaran</p>
          </p>
          <span class="Medium"><router-link :to="'/english'" >English</router-link></span>
          <span class="Medium"><router-link :to="'/mandarin'" >Mandarin</router-link></span>
          <span class="Medium"><router-link :to="'/math'" >Math</router-link></span>
          <span class="Medium"><router-link :to="'/science'" >Science</router-link></span>
        </div>
        <div class="list list1">
          <p class="title SemiBold" v-show="language=='en'">About us</p>
          <p class="title SemiBold" style="font-size:0.14rem;margin-bottom:0.24rem;" v-show="language=='yn'">Tentang kami</p>
          <!-- <span class="Medium">{{language=='en'?'Teachers':'Pengajar'}}</span> -->
          <span class="Medium" @click.stop="showEmailWrap" style="cursor: pointer;">{{language=='en'?'Join us':'Bergabung dengan kami'}}</span>
        </div>
      </div>
      <div class="contect">
        <div class="icon">
          <img src="../assets/images/foot_smell.png" alt="">
        </div>
        <div class="title Bold">Say Hi</div>
        <div class="desc Medium">
          <span>www.wondermindedu.com</span>
          <span>81110020292</span>
        </div>
      </div>
    </div>
    <div class="imgs">
      <span class="item" @click="goPath('ins')"><img src="../assets/images/foot_ins.png" alt=""></span>
      <span class="item"  @click="goPath('fb')"><img src="../assets/images/foot_fb.png" alt=""></span>
      <span class="item" @click="goPath('in')"><img src="../assets/images/foot_in.png" alt=""></span>
    </div>
  </div>
  <div class="email-wrap" id="email" @click.stop="showEmailWrap">
    <span v-show="language=='en'">Please send your CV to <span class="Bold">dewi@wondermindedu.com</span></span>
    <span v-show="language=='yn'">Silahkan kirim CV ke <span class="Bold">dewi@wondermindedu.com</span></span>
  </div>
</template>

<script setup scoped>
import bus from '@/utils/bus'
import { ref, reactive, onMounted } from 'vue'
let props = defineProps({
  trainingSid: String,
  language:String
})
const goPath = (type) => {
let path = ''
if(type == 'ins'){
  path = 'https://instagram.com/wondermind_edu?igshid=MzRlODBiNWFlZA=='
}else if(type == 'fb'){
  path = 'https://www.facebook.com/profile.php?id=61552631813342&mibextid=ZbWKwL'
}else if(type == 'in'){
  path = 'https://www.linkedin.com/company/wondermindedu/mycompany/'
}
  window.open(path,'_blank')
}
let email =null;

onMounted(() => {
  

  email = document.getElementById('email');
  console.log(email)
}
)

const showEmailWrap = () =>{
  console.log('sssssssssssssss')
  email.style.bottom = '0px';
}
const hideFormWrap = () =>{
  console.log('kkkkkkkkkkkk')
  email.style.bottom = '-600px';
}
bus.on('hideEmail',()=>{
  console.log('4')
  email.style.bottom = '-600px';
})
bus.on('showEmail',()=>{
  console.log('3')
  email.style.bottom = '0px';
})
  
</script>

<style lang="scss" scoped>

.email-wrap{
    position: fixed;
    left: 0;
    bottom:-600px;
    width: 100%;
    height: 1.8rem;
    background: rgba(249, 66, 58, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.15rem;
    box-sizing: border-box;
    transition: all .5s;
    z-index: 2000;
    font-size: 0.24rem;
    color:#fff;
}

.footer-logo{
  padding-left:1.15rem;
  img{
    width:1.62rem;
    height: 0.45rem;
    display: block;
  }
}
.center {
  padding-left: 1.15rem;
  padding-right: 1.15rem;
  display: flex;
  justify-content: space-between;

  .lists {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: 0.4rem;

    .list {
      display: flex;
      flex-direction: column;
      margin-top: 0.26rem;
      color: #fff;

      a{
        text-decoration: none;
        color:#fff;
        &:hover{
          color:#fff
        }
      }

      .title {
        font-size: 0.16rem;
        margin-bottom: 0.1rem;
      }

      span {
        font-size: 0.11rem;
        line-height: 0.22rem;
      }
    }

    .list1 {
      // margin-right: 0.52rem;
    }
  }

  .contect {
    position: relative;
    color:#fff;
    .title{
      font-size: 0.20rem;
      margin-bottom: 0.06rem;
      text-align: right;
    }
    .desc{
      font-size: 0.126rem;
      text-align: right;
      display: flex;
      flex-direction: column;
    }
  }
}

.imgs {
  display: flex;
  justify-content: flex-end;
  // margin-bottom: 0.4rem;
  padding: 0 1.15rem;
  box-sizing: border-box;

  .item {
    width: 0.21rem;
    height: 0.21rem;
    border-radius: 50%;
    background: #fff;
    margin-left: 0.08rem;
    display: flex;
    img{
      width: 100%;
      height: 100%;
    }
  }
}

.icon{
  position: absolute;
  right: 0.6rem;
    top: -0.14rem;
    img{
      width: 0.26rem;
      display: block;
    }
}

</style>
