<template>
  <div class="fullpage" id="wrap">
    <div class="bg" id="bg">
      <img class="bg-img"  src="../../assets/images/home/full1_img1.png"  alt="">
      <div class="shadow1"></div>
      <div class="shadow"></div>
      <div class="logo animate__animated animate__zoomIn animate_yc_3">
        <img src="../../assets/images/home/full1_img6.png" alt="">
      </div>
      <div class="txt">
        <h1 class="ExtraBold animate__animated animate__fadeInDown">{{ language == 'en' ? bigTitle.eng : bigTitle.yinni
        }}</h1>
        <div class="animate__animated animate__fadeInDown animate_yc_half">
          <p class="desc1 Medium" v-html="language == 'en' ? desc1.eng : desc1.yinni"></p>
          <p class="desc2 ExtraBoldItalic">
            <span class="ExtraBold">5-18</span>
          </p>
        </div>
      </div>
      <p></p>
    </div>
    <div class="icons" id="tt">
      <div class="icon-item ExtraBold animate__animated animate__bounceInUp animate_yc_1_2">
        <img src="../../assets/images/home/full1_img2.png" alt="">
        <div>ENGLISH</div>
      </div>
      <div class="icon-item ExtraBold animate__animated animate__bounceInUp animate_yc_1_5">
        <img src="../../assets/images/home/full1_img3.png" alt="">
        <div>MANDARIN</div>
      </div>
      <div class="icon-item ExtraBold animate__animated animate__bounceInUp animate_yc_1_7">
        <img src="../../assets/images/home/full1_img4.png" alt="">
        <div>MATH</div>
      </div>
      <div class="icon-item ExtraBold animate__animated animate__bounceInUp animate_yc_2">
        <img src="../../assets/images/home/full1_img5.png" alt="">
        <div>SCIENCE</div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { onMounted, reactive ,computed,ref} from 'vue';
let props = defineProps({
  language:String
})
let bigTitle = reactive({
  eng: 'WELCOME TO WONDERMIND',
  yinni: 'WELCOME TO WONDERMIND',
})
let desc1 = reactive({
  eng: 'We offer premium educational solutions tailored for Indonesian learners aged',
  yinni: 'Kami menawarkan solusi pendidikan premium yang dirancang untuk pelajar Indonesia usia:',
})
// let desc2 = reactive({
//   eng: 'We offer premium educational solutions tailored for Indonesian learners aged',
//   yinni: 'welcome to WonderMind~',
// })




onMounted(()=>{
  // let wrap = document.getElementById('wrap');
  // let bg = document.getElementById('bg');
  // let tt = document.getElementById('tt');
  // let w = document.documentElement.clientWidth;
  // let h = document.documentElement.clientHeight;
  // if(h>900){
  //   bg.style.height = '75%'
  //   tt.style.height = '25%'
  // }


  // wrap.style.height=h+'px'

})


</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.fullpage {
  width: 100%;
  min-width:1200px;
  height: 100%;
  // min-height: 660px;
  background: rgba(249, 66, 58, 1);
  display: flex;
  flex-direction: column;
  overflow:hidden;
}

.bg {
  width: 100%;
  // height: 4rem;
  height: 75%;
  // background-image: url('../../assets/images/home/full1_img1.png');
  // background-position: 50% center;
  // background-size: 100%;
  color: #fff;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .bg-img{
    width: 100.1%;
    // height: 100%;
    position: absolute;
    left:0;
    top:-1.36rem;
  }
  .shadow{
    position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(8, 8, 8, 0.2) 76.82%, rgba(43, 43, 43, 1) 100%);
  }
  .shadow1{
    position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, rgba(0, 0, 0, 0) 0%, rgba(8, 8, 8, 0.2) 76.82%, rgba(43, 43, 43, 1) 100%);
  }

  .logo {
    position: absolute;
    left: 53%;
    top: -3%;
    img{
      width: 0.6rem;
      height: 0.6rem;
    }
  }

  .txt {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 0.3rem;
    // height: 34%;
    // height: 1.38rem;
  }

  h1 {
    font-size: 0.32rem;
    line-height: 0.32rem;
  }

  .desc1 {
    font-size: 0.126rem;
    line-height: 0.18rem;
  }

  .desc2 {
    font-size: 0.12rem;
    line-height: 0.32rem;
    display: flex;
    align-items: baseline;
    justify-content: center;
    font-weight: normal;
    span{
      font-size: 0.32rem;
    line-height: 0.32rem;
      transform: skewX(-15deg);
      margin: 0 0.1rem;
    }
  }
}

.icons {
  width: 100%;
  // height: 1.4rem;
  height: 25%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0.14rem;
    color: #fff;
    margin: 0 0.28rem;

    img {
      width: 0.66rem;
      height: 0.66rem;
      margin-bottom: 0.12rem;
    }
  }
}

.animate_yc_half{
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
.animate_yc_1_2{
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
  -o-animation-delay:1.2s;
  animation-delay: 1.2s;
  --animate-delay: 1.2s;
}
.animate_yc_1_5{
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
  -o-animation-delay:1.5s;
  animation-delay: 1.5s;
  --animate-delay: 1.5s;
}

.animate_yc_1_7{
  -webkit-animation-delay: 1.7s;
  -moz-animation-delay: 1.7s;
  -o-animation-delay:1.7s;
  animation-delay: 1.7s;
  --animate-delay: 1.7s;
}
.animate_yc_2{
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay:2s;
  animation-delay: 2s;
  --animate-delay: 2s;
}
.animate_yc_3{
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay:3s;
  animation-delay: 3s;
  --animate-delay: 3s;
}
</style>
