<template>
<router-view></router-view>
</template>

<script setup>
import bus from '@/utils/bus';
import { setLocalStorage,getLocalStorage } from '@/utils/storage';
if(!getLocalStorage('language')){
  setLocalStorage('language','english')
}
function setHtmlSize(){
  var pageWidth = window.innerWidth || document.documentElement.clientWidth;
  pageWidth= pageWidth<=1200? 1200:pageWidth
  var baseFontSize =200;
  var maxSize = 1920;
 
  var fontSize = pageWidth/maxSize*baseFontSize;
  var elementBody = document.querySelector('body,html')
  elementBody.style.fontSize = fontSize + 'px';
}
function resize(){
  setHtmlSize();

  
  bus.emit('sizechange')
}
if (window.attachEvent) { 
  window.attachEvent("resize", resize); 
  
} else if (window.addEventListener) { 
  window.addEventListener("resize", resize, false);   
  // var elementBody = document.querySelector('body,html')
  // elementBody.addEventListener("fullscreenchange", function() {
  //   if (document.fullscreen) {
  //       console.log("进入全屏");
  //   } else {
  //       console.log("退出全屏");
  //   }
// });


}
setHtmlSize();



const imgList = [
require('./assets/images/home/full1_img1.png'),
require('./assets/images/age5_8/full1_img1.png'),
require('./assets/images/age9_11/full1_img1.png'),
require('./assets/images/english/full1_img1.png'),
require('./assets/images/mandarin/full1_img1.png')
    ];

    //图片预加载
    const loadImg = (imgList) => {
      for (let i = 0; i < imgList.length; i++) {
        let img = new Image()
        let currentSrc = ''
        img.src = imgList[i]
        img.onload = function (e) {
          //二次缓存，主要针对带中文的图片链接
          if (currentSrc.length === 0) {
            currentSrc = this.currentSrc
            img.src = currentSrc
           
          }

        }
        img.onerror = function (e) {

        }
      }
    }

    loadImg(imgList);
    // onMounted(() => {
    // })

</script>

<style lang="scss">

* {
  padding: 0;
  margin: 0;
}
html,
body {
  
  width: 100%;
  min-width: 1200px;
  min-height: 540px;
  // overflow: scroll;
}
::-webkit-scrollbar {
    width: 7px;//修改滚动条宽度
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #666666;
}
//鼠标移入样式
::-webkit-scrollbar-thumb:hover {
    background: #333333;
}

::-webkit-scrollbar-track {
    border-radius: 8px;
    background: rgba(0,0,0,.1);
}

</style>
