<template>
  <div class="fullpage2">
    <div class="page-title" v-if="language=='en'">
      <div class="t1 Bold">BE A SEEKER</div>
      <div class="t2 ExtraBold">LEARN BEYOND BOUNDARIES</div>
    </div>
    <div class="page-title" v-if="language=='yn'">
      <div class="t1 Bold">JADILAH SEORANG SEEKER </div>
      <div class="t2 ExtraBold">BELAJAR TANPA BATAS</div>
    </div>
    <div class="page-content">
      <div class="tabs">
        <div class="tab-item" @click="tabId=1">
          <img class="" v-if="tabId == 1" src="../../assets/images/age9_11/full3_tab1_active.png" />
          <img class="normal" v-else src="../../assets/images/age9_11/full3_tab1.png" />
        </div>
        <div class="tab-item"  @click="tabId=2">
          <img class="" v-if="tabId == 2" src="../../assets/images/age9_11/full3_tab2_active.png" />
          <img class="normal" v-else src="../../assets/images/age9_11/full3_tab2.png" />
        </div>
        <div class="tab-item"  @click="tabId=3">
          <img class="" v-if="tabId == 3" src="../../assets/images/age9_11/full3_tab3_active.png" />
          <img class="normal" v-else src="../../assets/images/age9_11/full3_tab3.png" />
        </div>
        <div class="tab-item"  @click="tabId=4">
          <img class="" v-if="tabId == 4" src="../../assets/images/age9_11/full3_tab4_active.png" />
          <img class="normal" v-else src="../../assets/images/age9_11/full3_tab4.png" />
        </div>
      </div>
      <div class="imgs" v-if="language=='en'">
        <img v-show="tabId == 1" src="../../assets/images/age9_11/full3_tab1_img.png" />
        <img v-show="tabId == 2" src="../../assets/images/age9_11/full3_tab2_img.png" />
        <img v-show="tabId == 3" src="../../assets/images/age9_11/full3_tab3_img.png" />
        <img v-show="tabId == 4" src="../../assets/images/age9_11/full3_tab4_img.png" />
      </div> 
      <!-- <div class="imgs" v-if="language=='yn'">
        <img v-show="tabId == 1" src="../../assets/images/age9_11/full3_tab1_img_yinni.png" />
        <img v-show="tabId == 2" src="../../assets/images/age9_11/full3_tab2_img_yinni.png" />
        <img v-show="tabId == 3" src="../../assets/images/age9_11/full3_tab3_img_yinni.png" />
        <img v-show="tabId == 4" src="../../assets/images/age9_11/full3_tab4_img_yinni.png" />
      </div>  -->
    </div>

  </div>
</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive,ref } from 'vue';
let tabId = ref(1)
let props = defineProps({
  language:String
})



</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.fullpage2 {
  width: 100%;
  height: 100%;
  background: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding:0 1.15rem;
  box-sizing: border-box;
  .page-title {
    text-align: center;
    margin-bottom: 0.3rem;
    margin-top: 0.39rem;

    .t1 {
      color: rgba(5, 195, 222, 1);
      font-size: 0.18rem;
      line-height: 0.23rem;
    }

    .t2 {
      color: rgba(34, 45, 92, 1);
      font-size: 0.27rem;
      line-height: 0.30rem;
    }
  }

  .page-content {
    .tabs {
      display: flex;
      justify-content: center;
      margin-bottom:0.12rem;

      .tab-item {
        margin: 0 0.08rem;
        width: 1.67rem;
        height: 0.77rem;
        border-radius: 0.06rem;
        display:flex;
        flex-direction: column;
  

        // overflow: hidden;
        .normal{
          border-radius: 0.06rem;
         
          margin: 0 auto;
          box-shadow:  0  0.02rem 0.04rem 0rem rgba(34, 45, 92, 0.2);
        }

        img {
          width: 100%;
        }
      }
    }
    .imgs{
      text-align:center;
      img{
        width: 7.29rem;
        height: 2.93rem;
      }
    }
  }

}
</style>
