<template>
  <div class="container1" id="container" @click.stop="hideForm"> 
    <div class="head" v-show="index == 0">
      <HeaderColor  @showForm="showForm" />
    </div>
    <div class="section1">
      <FullOne :language="lan" />
    </div>

    <div class="section1">
      <FullFour :language="lan" />
    </div>

    <div class="form-wrap" id="form"  @click.stop=" bus.emit('showForm')">
            <Form :sendBg="'#fff'" :sendColor="'rgba(249, 66, 58, 1)'" :language="lan" />
        </div>

  </div>
</template>
<script setup>
import bus from '@/utils/bus';
import HeaderColor from '../../components/HeaderColor.vue';
import Form from '../../components/Form.vue'
import FullOne from './FullOne.vue';

import FullFour from './FullFour.vue';
import { onMounted, ref,computed } from 'vue';
import { getLocalStorage } from '@/utils/storage';

let language = computed(()=>{
  let l = getLocalStorage('language')
  return l=='English'?'en':'yn';
})
let lan = ref('');
lan.value = language.value
bus.on('changeLan',(val)=>{
  lan.value =val
})

const hideForm = () => {
  form.style.bottom = '-600px';
  bus.emit('hideForm')
  bus.emit('hideEmail')
}
const showForm = ()=>{
  form.style.bottom = '0px';
}
var pageWidth = window.innerWidth || document.documentElement.clientWidth;
var pageHeight = window.innerHeight || document.documentElement.clientHeight
let index = ref(0); //当前索引
let isFull = false;
if (pageHeight > 1000) {
  isFull = true;
}
// let pix = isFull ? 1080 / 1920 : 963 / 1920;
let pix = 1080 / 1920
const changeSize = () => {
  let container = document.getElementById('container');
  let wraps = document.querySelectorAll('.section1');
  var newWidth = window.innerWidth || document.documentElement.clientWidth;
  var newHeight = window.innerHeight || document.documentElement.clientHeight;
  newWidth = newWidth < 1200 ? 1200 : newWidth;
  container.style.height = newHeight + 'px'
  wraps.forEach((item, index) => {
    if (index == 0) {
      console.log('新宽度：' + newWidth, '比例高度：' + newWidth * pix, '新高度：' + newHeight)
      if (newWidth > 1200) {
        item.style.height = newHeight < 660 ? '660px' : (newWidth * pix > newHeight ? newHeight : newWidth * pix + 'px');
        if (newWidth > 1200) {
          if (newHeight < 660) {
            item.style.height = '660px';
          } else {
            if (newWidth * pix >= newHeight) {
              item.style.height = newHeight + 'px';
            } else {
              item.style.height = newWidth * pix + 'px'
            }
          }
        }
        } else {
          item.style.height = '660px';
        }
      } else if (index == 1) {
        item.style.height = newWidth * pix + 'px';
      } else {
        item.style.height = 'auto'
      }
    })
  pageHeight = newHeight;
  pageWidth = newWidth;

}
let form =null
onMounted(() => {
  form = document.getElementById('form')
  let wraps = document.querySelectorAll('.section1');
  let container = document.getElementById('container');

  container.style.height = pageHeight + 'px'
  wraps.forEach((item, index) => {
    if (index == 0) {
      if (pageWidth > 1200) {
        if (pageHeight < 660) {
          item.style.height = '660px'
        } else {
          if (pageWidth * pix >= pageHeight) {
            item.style.height = pageHeight + 'px'
          } else {
            item.style.height = pageWidth * pix + 'px'
          }
        }
      } else {

        item.style.height = '660px';
      }
    } else if (index == 1) {
      // item.style.height = pageWidth * (pix) + 'px';
      item.style.height = 'auto'
    } else {
      item.style.height = 'auto'
    }
  })
  container.addEventListener('scroll', function (e) {
    if (e.target.scrollTop > 120) {
      index.value = 1;
    } else {
      index.value = 0;
    }
  });
  bus.on('sizechange', () => {
    console.log('changeSize')
    changeSize();
  })

  // //实现滚动效果
  // const container = document.querySelector('.container')
  // const lis = document.querySelectorAll('.controls li')
  // var viewHeight = null //声明页面高度
  // let pageNum = 2;
  // var flag = true; //节流开关
  // document.addEventListener('mousewheel', function (e) {
  //   e = e || window.event
  //   // console.log(e);
  //   e.preventDefault()
  //   // 获取整屏的高度
  //   viewHeight = document.body.clientHeight;
  //   if (flag) {  //节流阀
  //     flag = false
  //     if (e.wheelDelta > 0) {
  //       index.value--
  //       if (index.value < 0) {
  //         index.value = 0
  //       }
  //     } else {
  //       index.value++;
  //       if (index.value > pageNum - 1) {
  //         index.value = pageNum - 1
  //       }
  //     }
  //     // container.style.top = -index * viewHeight + 'px'
  //     // 为了防止用户一直触发这个事件，通过定时器来防止
  //     setTimeout(function () {
  //       flag = true
  //     }, 500)
  //   }

  // }, { passive: false, useCapture: false })
})



</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

* {
  padding: 0;
  margin: 0;
}

.container1 {
  overflow-y: scroll;
}
.form-wrap{
    position: fixed;
    left: 0;
    bottom:-600px;
    width: 100%;
    height: 1.8rem;
    background: rgba(249, 66, 58, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.15rem;
    box-sizing: border-box;
    transition: all .5s;
    z-index:2000;
}

// html,
// body {
//   width: 100%;
//   min-width: 1200px;
//   min-height: 540px;
//   overflow: scroll;
// }

// .container {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left:0;
//   transition: all 0.3s ease;
// }

// .section{
//   font-size: 0.3rem;
//   display: flex;
//   flex-direction: column;
// }


// .section {
//   width: 100%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-size: cover;
// }


.animate_yc_half {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}

.head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
}
</style>
