<template>
  <div class="fullpage">
    <div class="form">
      <Form :inpsAddress="'center'" :language="language" />
    </div>
    <div class="line-wrap"> 

<div class="line"></div>
</div>
    <div class="list">
      <FooterList :language="language" />
    </div>
    <div class="foot">
      <FooterAddress :language="language" />
    </div>
    
  </div>
</template>
<script setup>
import Form from '../../components/Form.vue'
import FooterAddress from '../../components/FooterAddress.vue'
import FooterList from '../../components/FooterList.vue'
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let title1 = reactive({
  eng: " ",
  yinni: 'welcome to WonderMind~',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.fullpage {
  width: 100%;
  height: 100%;
  background: rgba(37, 42, 85, 1);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.form{
  margin-top: 0.27rem;
  height: 1rem;
  width: 100%;
  padding-left: 1.15rem;
  padding-right: 1.15rem;
  box-sizing: border-box;
}
.line-wrap{
  width: 100%;
  padding: 0 1.15rem;
  box-sizing: border-box;
  .line {
  width: 100%;
  height: 0.005rem;
  background: #F9423A;
  margin: 0.38rem 0;
}
}

.list{
  width: 100%;
}


.foot{
  width:100%;
  position: absolute;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
}
</style>
