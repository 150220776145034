<template>
<div class="forms" >
  <div class="title" :style="{ 'text-align': titleAlign }">
    <h2 class="ExtraBold" v-if="language == 'en'" :style="{ 'text-align': titleAlign }">
      <span style="display: inline-block" class="animate__animated animate__heartBeat">FREE DEMO CLASS
      </span>
    </h2>
    <h2 class="ExtraBold" v-if="language == 'yn'" :style="{ 'text-align': titleAlign }">
      <span style="display: inline-block" class="animate__animated animate__heartBeat">DAPATKAN DEMO CLASS GRATIS</span>
    </h2>
    <div class="desc Medium" v-if="language == 'en'" :style="{ 'text-align': titleAlign }">
      Register now and get a free demo class!
    </div>
    <div class="desc Medium" v-if="language == 'yn'" :style="{ 'text-align': titleAlign }">
      Daftar sekarang dan dapatkan demo class gratis!
    </div>
  </div>
  <div class="inps" :style="{ 'justify-content': inpsAddress }">
    <input class="inp Medium" v-model="name" :placeholder="language == 'en' ? 'Name' : 'Nama'" />
    <input class="inp Medium" v-model="phone"  onkeyup="value=value.replace(/[^\d]/g,'')"  :placeholder="language == 'en' ? 'Phone  number' : 'Nomor telepon'" />
    <input class="inp Medium" v-model="age" onkeyup="value=value.replace(/[^\d]/g,'')"  @input="limitAge" :placeholder="language == 'en' ? 'Age' : 'Usia'" />
    <el-select v-model="subject" class="m-2" placeholder="Program" size="large" popper-class="">
      <el-option v-for="item in languages" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>
    <!-- <select class="inp Medium select-style"  >
        <option class="option" value='' disabled selected style='display:none;'>Program</option>
        <option class="option" value='0'>English</option>
        <option class="option" value='1'>Math</option>
      </select> -->
    <button class="btn Bold" @click="send" :style="{ background: sendBg, color: sendColor }" >
      {{ language == "en" ? "Send" : "Kirim" }}
    </button>
  </div>
  <!-- <div class="inps">
      <input class="inp Medium" placeholder="Name" />
      <input class="inp Medium" placeholder="Phone  number" />
      <input class="inp Medium" placeholder="Age" />
      <div class="dropdown">
      <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
        Program
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
        <li><a href="#">Action</a></li>
        <li><a href="#">Another action</a></li>
        <li><a href="#">Something else here</a></li>
        <li role="separator" class="divider"></li>
        <li><a href="#">Separated link</a></li>
      </ul>
    </div>
      <button class="btn Bold" :style="{background:sendBg,color:sendColor}">Send</button>
    </div> -->
</div>
</template>

<script setup>
import axios from "axios";
import qs from 'qs';
import bus from '@/utils/bus';
import {report} from '@/apis/API'
import {
  ref,
  reactive,
  watch
} from "vue";
let props = defineProps({
  sendBg: String,
  sendColor: String,
  inpsAddress: String,
  titleAlign: String,
  language: String,
});
let languages = reactive([{
    label: "English",
    value: "English"
  },
  {
    label: "Mandarin",
    value: "Mandarin"
  },
  {
    label: "Math",
    value: "Math"
  },
  {
    label: "Science",
    value: "Science"
  },
]);
let name = ref("");
let phone = ref("");
let age = ref("");
let subject = ref("");
let over = false;

const limitAge = (e)=>{
  console.log(age.value,e.detail.value)
  if( (age.value+'') > 2 ){age.value = (age.value+'').slice(0,2)}
}

const send = () => {
  if(over == true) return;
  over = true;
  let params = {
    name: name.value,
    phone: phone.value,
    age: age.value,
    subject: subject.value,
  };
  if(!name.value || !phone.value || !age.value || !subject.value){
    // window.alert("请将name，电话，年龄，科目填写完整");
    console.log(name.value ,phone.value ,age.value ,subject.value);
    return;
  }

report(params).then(res => {
  let msg = props.language == 'en' ?'Submitted':'Telah Dikirim'
  window.alert(msg);
  name.value = '';
  phone.value = '';
  age.value = '';
  subject.value='';
  
}).catch(error=>{
  console.log(error)
}).finally(() => {
  over = false;
})
  // console.log(params);
  // axios.post("/api/login", params, {
  //     headers: {
  //       // 'Content-Type': 'application/json',
  //       'Token': "iH6uI0oQ3rX6sL0x",
  //     },
  //   })
  //   .then((res) => {
  //     window.alert("已提交");
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   });
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__wrapper {
  box-shadow: none !important;
  height: 0.28rem;
  box-sizing: border-box;
}

::v-deep .el-select .el-input__wrapper.is-focus {
  box-shadow: none !important;
  height: 0.28rem;
  box-sizing: border-box;
}

::v-deep .el-select .el-input__inner {
  font-family: "Montserrat-Medium" !important;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  box-shadow: none !important;
}

::v-deep .el-select .el-input .el-select__caret {
  color: #f9423a;
  transform: scale(1.5);
}

.forms {
  width: 100%;

  .title {
    margin-bottom: 0.24rem;
    color: #fff;

    h2 {
      font-size: 0.27rem;
      line-height: 0.3rem;
    }

    .desc {
      font-size: 0.12rem;
      line-height: 0.16rem;
    }
  }

  .inps {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .inp {
    // width: 1.28rem;
    width: 1.44rem;
    height: 0.28rem;
    padding-left: 0.08rem;
    box-sizing: border-box;
    outline: none;
    border-radius: 0.04rem;
    background: #fff;
    border: 1px solid #fff;
    margin-right: 0.08rem;
    // color: rgba(0, 0, 0, 0.6);
    color:#606266;
    font-size: 0.12rem;
  }

  .btn {
    width: 0.8rem;
    height: 0.28rem;
    outline: none;
    border-radius: 0.04rem;
    background: rgba(249, 66, 58, 1);
    border: none;
    color: #fff;
    font-size: 0.14rem;
    line-height: 0.28rem;
  }

  ::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: rgba(0, 0, 0, 0.4);
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: rgba(0, 0, 0, 0.4);
  }

  ::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgba(0, 0, 0, 0.4);
  }
}
</style>
