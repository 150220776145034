import Cookies from 'js-cookie';

export function getCookies (key) {
    return Cookies.get(key);
}

export function setCookies (key, value) {
    Cookies.set(key, value);
}

export function removeCookies (key) {
    Cookies.remove(key);
}

export function getStorage (key) {
    return sessionStorage.getItem(key);
}

export function setStorage (key, value) {
    sessionStorage.setItem(key, value);
}

export function removeStorage (key) {
    sessionStorage.removeItem(key);
}

export function getLocalStorage (key) {
    return localStorage.getItem(key);
}

export function setLocalStorage (key, value) {
    localStorage.setItem(key, value);
}

export function removeLocalStorage (key) {
    localStorage.removeItem(key);
}

