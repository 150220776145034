<template>
  <div class="fullpage" @click.stop="hideFormWrap">
    <div class="top">
      <div class="ExtraBold"  v-if="language == 'en'">EXCITING CAMPUS EVENTS</div>
      <div class="ExtraBold"  v-if="language == 'yn'">KEGIATAN MENARIK DAN MENYENANGKAN</div>
    </div>
    <div class="bottom">

<div class="empty"></div>
      <div class="swiper-container" style="overflow: hidden;height:auto">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="overflow: hidden;">
            <div class="items-wrap">
              <div class="items">
                <div class="item">
                  <img src="../../assets/images/home/full7-img1.png" alt="">
                  <h2 class="title Bold">Trial Class</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">{{ language == 'en'? 'Everyday':'Setiap hari'}}</span>
                  </div>
                  <div class="btn Bold" @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
                <div class="item">
                  <img src="../../assets/images/home/full7-img2.png" alt="">
                  <h2 class="title Bold">Fun Activities</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">{{ language == 'en'? 'Every weekend':'Setiap weekend'}}</span>
                  </div>
                  <div class="btn Bold" @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
                <div class="item">
                  <img src="../../assets/images/home/full7-img3.png" alt="">
                  <h2 class="title Bold">The Halloween Party</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">{{ language == 'en'? 'Nov 1, 2023':'1 November 2023'}}</span>
                  </div>
                  <div class="btn Bold" @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
               
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="overflow: hidden;">
            <div class="items-wrap">
              <div class="items">
                <div class="item">
                  <img src="../../assets/images/home/full7-img1.png" alt="">
                  <h2 class="title Bold">Demo Lessons</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">Everyday</span>
                  </div>
                  <div class="btn Bold"  @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
                <div class="item">
                  <img src="../../assets/images/home/full7-img2.png" alt="">
                  <h2 class="title Bold">Mini Drama Shows</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">Oct 16, 2023</span>
                  </div>
                  <div class="btn Bold"  @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
                <div class="item">
                  <img src="../../assets/images/home/full7-img3.png" alt="">
                  <h2 class="title Bold">The Halloween Party</h2>
                  <div class="time">
                    <img src="../../assets/images/home/full7-time-icon.png" />
                    <span class="line"></span>
                    <span class="Bold">Nov 1, 2023</span>
                  </div>
                  <div class="btn Bold"  @click.stop="showFormWrap"><span>Join</span> </div>
                </div>
                

              </div>
            </div>
          </div>
        </div>
       
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination">
          
        </div>
      </div>




    </div>
    <div class="form-wrap" id="form"  @click.stop=" bus.emit('showForm')">
            <Form :sendBg="'#fff'" :sendColor="'rgba(249, 66, 58, 1)'" :language="language" />
            
        </div>
  </div>
</template>
<script setup>
import bus from '@/utils/bus'
import Form from '../../components/Form.vue'
import Swiper, { Navigation, Pagination } from 'swiper'
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
Swiper.use([Navigation, Pagination])
let props = defineProps({
  language:String
})
// let swiperOptions = reactive({

//   slidesPerView: 3,//显示个数
//   direction: 'vertical',
//    pagination: {
//       el: '.swiper-pagination'
//    }

// })

let form =null;

onMounted(() => {
  new Swiper('.swiper-container', {
    loop: true,
    // 如果需要分页器
    pagination: {
      el:'.swiper-pagination',
      clickable:true
    },
    //如果需要自动切换海报
    autoplay: {
      delay: 1000,//时间 毫秒
      disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
    },
  })

  form = document.getElementById('form');
  console.log(form)
}
)

const showFormWrap = () =>{
  console.log('sssssssssssssss')
    form.style.bottom = '0px';
}
const hideFormWrap = () =>{
  console.log('kkkkkkkkkkkk')
  form.style.bottom = '-600px';
}
bus.on('hideForm',()=>{
  console.log('4')
  form.style.bottom = '-600px';
})
bus.on('showForm',()=>{
  console.log('3')
  form.style.bottom = '0px';
})
  





</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.form-wrap{
    position: fixed;
    left: 0;
    bottom:-600px;
    width: 100%;
    height: 1.8rem;
    background: rgba(249, 66, 58, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.15rem;
    box-sizing: border-box;
    transition: all .5s;
    z-index: 2000;
}

.swiper-container {
  height: 500px;
  width: 100%;

  .swiper-wrapper {
    .swiper-slide {
      width: 100%;
      height: 100%;
      text-align: center;
      // line-height: 500px;
    }
  }
}

.fullpage {
  width: 100%;
  height: 100%;
  background: #F9423A;
  display: flex;
  flex-direction: column;
  justify-content: center;

  box-sizing: border-box;
  // padding-bottom: 0.8rem;

  .top {
    width: 100%;
    margin-bottom: 0.48rem;
    padding-top: 0.48rem;
    text-align: center;
    color: #fff;
    font-size: 0.27rem;
    line-height: 0.3rem;
  }
}

.bottom {
  // flex: 1;
  height: 4rem;
  width: 100%;
  overflow: hidden;
  padding: 0 0.87rem;
  position: relative;
  box-sizing: border-box;
  display: flex;

  .dots {
    margin-top: 0.3rem;
    display: flex;
    justify-content: center;
    margin-left: -1.56rem;

    span {
      width: 0.10rem;
      height: 0.14rem;
      border-radius: 0.02rem;
      transform: rotate(45deg);
      background: #FFF;
      margin-right: 0.12rem;
      opacity: 0.4;
    }
  }

  .items-wrap {
    display: flex;
    flex-wrap: nowrap;

  }

  .items {
    display: flex;
    flex-wrap: nowrap;

  }

  .item {
    flex: 0 0 2.43rem;
    width: 2.74rem;
    height: 3.12rem;
    border-radius: 0.06rem;
    background: #fff;
    margin-right: 0.24rem;
    overflow: hidden;

    img {
      width: 101%;
      height: 2.06rem;
      display: block;
      position: relative;
      top: -1px;
    }

    .title {
      color: #252A55;
      font-size: 0.2rem;
      margin-top: 0.08rem;
      margin-bottom: 0.04rem;
      margin-left: 0.18rem;
      line-height: 0.2rem;
      text-align: left;
    }

    .time {
      color: rgba(5, 195, 222, 1);
      font-size: 0.16rem;
      margin-left: 0.18rem;
      display: flex;
      align-items: center;
      line-height: 0.16rem;
      text-align: left;

      img {
        width: 0.1rem;
        height: 0.09rem;
      }

      .line {
        width: 1px;
        height: 0.13rem;
        background: #979797;
        margin: 0 0.04rem 0 0.06rem;
      }
    }

    .btn {
      text-align: right;
      margin-right: 0.18rem;
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      margin-top: 0.06rem;

      span {
        display: block;
        width: 0.7rem;
        height: 0.33rem;
        line-height: 0.33rem;
        border: 0.01rem solid rgba(249, 66, 58, 1);
        font-size: 0.14rem;
        color: rgba(249, 66, 58, 1);
        border-radius: 0.04rem;
        text-align: center;
      }
    }
  }

}

::v-deep .swiper-pagination-bullet{
  width: 0.12rem!important;
  height: 0.2rem!important;
  background: #fff!important;
  transform: rotate(45deg)!important;
  border-radius: 0.04rem!important;
  margin: 0 0.11rem!important;
}
.swiper-pagination-bullet-active{
  opacity:0.3!important;
}
.swiper-pagination{
  margin-top:1px;
  height: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.swiper-wrapper{
  z-index:88;
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
z-index:99;
}
// .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal{
//   // bottom: -30px;
//   top: 3.16rem!important;
// }
</style>
