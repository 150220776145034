import { createApp } from 'vue'
import App from './App.vue'
import 'element-plus/dist/index.css'
import router from '@/router/index'
// import VueLazyload from 'vue-lazyload'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/reset.css'


import './assets/swiper.css'

import 'animate.css'


    // // 设计稿：1920 * 1080
    // // 1.设计稿尺寸
    // let targetWidth = 1920;
    // // 2.拿到当前设备（浏览器）的宽度
    // // document.documentElement  获取html的宽度
    // let currentWidth =
    //   document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth;
    // // 3.计算缩放比率(屏幕过宽，根据高度计算缩放比例)
    // let scaleRatio = currentWidth / targetWidth; 
    // // 4.开始缩放网页
    // document.body.style = `transform: scale(${scaleRatio});transform-origin: left top`;




// createApp(App).use(router).use(VueLazyload).use(ElementPlus).mount('#app')
createApp(App).use(router).use(ElementPlus).mount('#app')
