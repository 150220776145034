<template>
  <div class="fullpage">
    <div class="left">
      <img class="animate__animated animate__fadeInUpBig " src="../../assets/images/home/full8-img1.png" alt="">
        <div class="txt Bold" v-if="language == 'en'">
          <span>ABOUT</span>
          <span>WONDER</span>
          <span>MIND</span>
        </div>
        <div class="txt Bold" v-if="language == 'yn'">
          <span>TENTANG</span>
          <span>WONDER</span>
          <span>MIND</span>
        </div>
    </div>
    <div class="right">
      <div class="txt Medium" v-if="language == 'en'">
        <span class="animate__animated animate__fadeInUp animate_yc_half"><span class="ExtraBold" style="font-size: 0.13rem;"> WonderMind</span>, co-founded in Singapore by highly </span>
        <span class="animate__animated animate__fadeInUp animate_yc_1">experienced education professionals, specializes in </span>
        <span class="animate__animated animate__fadeInUp animate_yc_2">delivering <i  class="ExtraBold">top-quality educational products </i>  tailored for</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_3">Indonesian learners. Our programs encompass a variety </span>
        <span  class="animate__animated animate__fadeInUp animate_yc_4">of subjects such as Mandarin, English, Mathematics, and </span>
        <span  class="animate__animated animate__fadeInUp animate_yc_5">Science, aiming to empower students with the skills and </span>
        <span  class="animate__animated animate__fadeInUp animate_yc_6">knowledge necessary for future success.</span>
      </div>
               
      <div class="txt Medium" v-if="language == 'en'">
        <span class="animate__animated animate__fadeInUp animate_yc_7"><span class="ExtraBold" style="font-size: 0.13rem;">We</span> are guided by core values:  <i  class="ExtraBold">Customer Obsession,</i> </span>
        <span class="animate__animated animate__fadeInUp animate_yc_8"> <i  class="ExtraBold">Reliability, Practicality, and Efficiency.</i> These principles</span>
        <span class="animate__animated animate__fadeInUp animate_yc_9"> inspire us to provide exceptional education services</span>
        <span class="animate__animated animate__fadeInUp animate_yc_10"> focused on customer satisfaction, dependability, </span>
        <span class="animate__animated animate__fadeInUp animate_yc_11">practical solutions, and efficient operations.</span>
      </div>

      <div class="txt Medium" v-if="language == 'yn'">
        <span class="animate__animated animate__fadeInUp animate_yc_half"><span class="ExtraBold" style="font-size: 0.13rem;"> WonderMind</span>, co-founded di Singapura oleh ahli-ahli</span>
        <span class="animate__animated animate__fadeInUp animate_yc_1">profesional yang berpengalaman tinggi, berkhususkan </span>
        <span class="animate__animated animate__fadeInUp animate_yc_2">untuk menghadirkan produk pendidikan berkualitas terbaik</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_3"> yang ditargetkan untuk pelajar Indonesia. Program kami</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_4">mencakup banyak mata pelajaran, seperti Bahasa</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_5">Mandarin, Bahasa Inggris, Matematika, dan Sains,</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_6">bertujuan untuk membekali pelajar dengan</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_7">keterampilan dan pengetahuan untuk kesuksesan di</span>
        <span  class="animate__animated animate__fadeInUp animate_yc_8">masa depan.</span>
      </div>
           
      <div class="txt Medium" v-if="language == 'yn'">
        <span class="animate__animated animate__fadeInUp animate_yc_9"><span class="ExtraBold" style="font-size: 0.13rem;">Kami</span> memiliki prinsip-prinsip utama yaitu: Customer</span>
        <span class="animate__animated animate__fadeInUp animate_yc_10"> obsession, Reliability, Practicality, dan Efficiency.</span>
        <span class="animate__animated animate__fadeInUp animate_yc_11"> Prinsip-prinsip ini menginspirasi kami untuk</span>
        <span class="animate__animated animate__fadeInUp animate_yc_12">menyediakan jasa edukasi terbaik yang difokuskan pada  </span>
        <span class="animate__animated animate__fadeInUp animate_yc_13">kepuasan pelanggan, keandalan, solusi praktis, dan</span>
        <span class="animate__animated animate__fadeInUp animate_yc_14">pelaksanaan yang efisien.</span>

      </div>
     </div>
  </div>
</template>
<script setup>
import { getLocalStorage } from '@/utils/storage';
import { onMounted, reactive } from 'vue';
let props = defineProps({
  language:String
})
let title1 = reactive({
  eng: " ",
  yinni: 'welcome to WonderMind~',
})




</script>
<style lang="scss" scoped>
@import '@/assets/font/font.css';

.fullpage {
  width: 100%;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  display: flex;
  .left{
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    
    img{
      width:2.85rem;
      height: 3.03rem;
      padding-left: 1.07rem;
      opacity: 0.1!important;
    }
    .txt{
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    font-size: 0.45rem;
    color:rgba(34, 45, 92, 1);
    text-align: center;
    line-height: 0.39rem;
    }
  }
  .right{ 
    width: 50%;
    height: 100%;
    display: flex;
      flex-direction: column;
      justify-content: center;
    .txt{
      display: flex;
      flex-direction: column;
      line-height: 0.18rem;
      font-size: 0.126rem;
      color:rgba(34, 45, 92, 1);
      margin-bottom:0.16rem;
    }
    
  }
  
}
.animate_yc_half{
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
  --animate-delay: 0.5s;
}
.animate_yc_1{
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  -o-animation-delay: 1s;
  animation-delay: 1s;
  --animate-delay: 1s;
}

.animate_yc_2{
  -webkit-animation-delay: 2s;
  -moz-animation-delay: 2s;
  -o-animation-delay: 2s;
  animation-delay: 2s;
  --animate-delay: 2s;
}

.animate_yc_3{
  -webkit-animation-delay: 3s;
  -moz-animation-delay: 3s;
  -o-animation-delay: 3s;
  animation-delay: 3s;
  --animate-delay: 3s;
}

.animate_yc_4{
  -webkit-animation-delay: 4s;
  -moz-animation-delay: 4s;
  -o-animation-delay: 4s;
  animation-delay: 4s;
  --animate-delay: 4s;
}

.animate_yc_5{
  -webkit-animation-delay: 5s;
  -moz-animation-delay: 5s;
  -o-animation-delay: 5s;
  animation-delay: 5s;
  --animate-delay: 5s;
}
.animate_yc_6{
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
  --animate-delay: 6s;
}
.animate_yc_7{
  -webkit-animation-delay: 7s;
  -moz-animation-delay: 7s;
  -o-animation-delay: 7s;
  animation-delay: 7s;
  --animate-delay: 7s;
}
.animate_yc_8{
  -webkit-animation-delay: 8s;
  -moz-animation-delay: 8s;
  -o-animation-delay: 8s;
  animation-delay: 8s;
  --animate-delay: 8s;
}
.animate_yc_9{
  -webkit-animation-delay: 9s;
  -moz-animation-delay: 9s;
  -o-animation-delay: 9s;
  animation-delay: 9s;
  --animate-delay: 9s;
}
.animate_yc_10{
  -webkit-animation-delay: 10s;
  -moz-animation-delay: 10s;
  -o-animation-delay: 10s;
  animation-delay: 10s;
  --animate-delay: 10s;
}
.animate_yc_11{
  -webkit-animation-delay: 11s;
  -moz-animation-delay: 11s;
  -o-animation-delay: 11s;
  animation-delay: 11s;
  --animate-delay: 11s;
}
.animate_yc_12{
  -webkit-animation-delay: 12s;
  -moz-animation-delay: 12s;
  -o-animation-delay: 12s;
  animation-delay: 12s;
  --animate-delay: 12s;
}
.animate_yc_13{
  -webkit-animation-delay: 13s;
  -moz-animation-delay: 13s;
  -o-animation-delay: 13s;
  animation-delay: 13s;
  --animate-delay: 13s;
}
.animate_yc_14{
  -webkit-animation-delay: 14s;
  -moz-animation-delay: 14s;
  -o-animation-delay: 14s;
  animation-delay: 14s;
  --animate-delay: 14s;
}


</style>
